<template>
  <router-link
    :to="{name: 'project', params: {projectName: project.title.toLowerCase().replace(/ /g,'_')}}" class="ProjectElement">
    <div class="project-image-container">
    <img v-bind:src='project.mainImage' alt="" class="projectImage">
    </div>
    <div class="description-container">
    <h2>{{project.title}}</h2>
    <h4>{{project.subtitle}}</h4>
    </div>
   
    <div class="button">
    <h2>more</h2>
      <svg class="arrow" width="50" height="31" viewBox="0 0 50 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M47.4472 15.1975L0.500001 15.1976M47.4472 15.1975L33.4472 1.19753M47.4472 15.1975L33.4472 29.1975" />
    </svg>
    </div>
    </router-link>

</template>

<script>

export default {
  name: 'projectListElement',
  props: {
    project: Object
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.ProjectElement{
  display: grid;
  grid-template-rows: 4fr 1fr;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: 
  "image image" 
  "description button";
  border-bottom:$border;
  border-right:$border;
  text-align: left;
  height: 66vh;

  
}
.projectImage{

  width: 100%;
 height: 100%;
 object-fit: cover;
}

.project-image-container{
  grid-column-start: 1;
  grid-column-end: 2;
  height: 100%;
  overflow: hidden;
  grid-area: image;
  border-bottom: $border;
}
.description-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-area: description;
  border-right: $border;
  padding-left: $padding;
  padding-top: $padding;
  text-transform: capitalize;
  
}


.button{
  grid-area: button;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: $padding;
  color: $fontColor;
  cursor: url("../assets/icons/cursorCircle.svg"), pointer;
  
  // width: auto;
  // height: 100%;
  text-align: center;
  font-variation-settings: "wght" 100;

  stroke: $primaryColor;
  
}
.button h2{
  padding: 0px;
}
.arrow{
  stroke-width: $lines;
  margin: 0.7rem;
   transition: translate 0.3s ease-in-out 0ms;
}
.button:hover{
  stroke: $backgroundColor;
  color: $backgroundColor;
  background-color: $primaryColor;
  
}

.ProjectElement:hover .arrow {
  translate: 0.4rem 0rem;
 
}

</style>
