<template>
  <div class="contact-container">
      <!-- <a > -->
      <a
        class="contact-field"
        v-for="contact in contact"
        v-bind:key="contact.text"
        :contact="contact"
        :href="contact.url"
        target="_blank"
      >
      
        <svg  class="icon" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.1977 2.0524L1.80249 26.4476M26.1977 2.0524H6.39868M26.1977 2.0524V21.8514" />
        </svg>
        <h2>{{contact.text}}</h2>
        
       
      </a>
<!-- </a> -->
  </div>
</template>

<script>
import store from "@/store.js";
export default {
  name: 'contact',
  props: {
  },
  components:{
  },
    data() {
    return {
      contact: store.content.contact,
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.contact-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  //grid-template-rows: 1fr 1fr 1fr;
  
  @media screen 
  and (min-device-width: 1000px){ 
    grid-template-columns: 1fr 1fr;

}

}
// .contact-field::after {
//   content: "";
//   padding-bottom: 40%;
//   display: block;
// }

.contact-field{
  border-right: $border;
  border-bottom: $border;
  padding: 20px;
  text-decoration: none;
  color: $fontColor;
  cursor: url("../assets/icons/cursorCircle.svg"), pointer;
}

.contact-field:hover{
  background-color: $primaryColor;
  color: $backgroundColor;
}
.icon{
  stroke: $primaryColor;
  stroke-width: $lines;
}
.contact-field:hover .icon{
  stroke: $backgroundColor;
}
#about{
  top: -10px;
}

</style>
