<template>
  <div id='mainPageContent' body-scroll-lock-ignore>
    
    <titleBar class="section" id="about" content="About" textAlignment="left"/>
    <div class="about-container">
    <img v-bind:src='about.image' alt="" class="about-image">
    <div class="info">
    <h2 class="about-text" v-html="about.text"></h2>
    <div>
    <div class="links-container" id="container">
      <a v-for="formation in about.formations" v-bind:key="formation.title" :href="formation.url" target="_blank" class="link-button">
        <svg  class="icon" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.1977 2.0524L1.80249 26.4476M26.1977 2.0524H6.39868M26.1977 2.0524V21.8514" />
      </svg>
        <h2>{{formation.title}}</h2>
        <h4>{{formation.description}}</h4>
      </a>
      </div>
      <div class="link-button">
        <router-link
      :to="{
        name: 'portfolioMain',
      }" class="change-button"
      ><h4>See my UX Design related Projects</h4>
         <svg class="arrow" width="50" height="31" viewBox="0 0 50 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M47.4472 15.1975L0.500001 15.1976M47.4472 15.1975L33.4472 1.19753M47.4472 15.1975L33.4472 29.1975" />
    </svg>
      </router-link>
  
    </div>
    
    
    
    </div>
    </div>
    </div>
    
   
    <titleBar  class="section" id="projects" content="Projects" textAlignment="left"/>
        <div class="layout"><projectListElement 
          v-for="project in projects"
          v-bind:key="project.title"
          :project="project"
        />
        </div>
        
         <titleBar class="section" id="experiments" content="Experiments" textAlignment="left"/>
          <div class="layout">
         <experimentListElement 
          v-for="project in experiments"
          v-bind:key="project.title"
          :project="project"
        />
        </div>
        <div >
         <titleBar class="section" id="contact" content="Contact" textAlignment="left"/>
        <contact/>
        </div>
  </div>
</template>

<script>
import projectListElement from "@/components/projectListElement.vue"
import experimentListElement from "@/components/experimentListElement.vue"
import titleBar from "@/components/titleBar.vue"
import store from "@/store.js";
import contact from "@/components/contact.vue";
var VueScrollTo = require('vue-scrollto');
export default {
  name: 'mainPageContent',
  props: {
  },
  components:{
    projectListElement: projectListElement,
    experimentListElement: experimentListElement,
    titleBar: titleBar,
    contact: contact
  },
  mounted(){
    this.observeSections()
    VueScrollTo.scrollTo("#"+this.$route.name)
    
  },

  data() {
    return {
      about: store.content.about,
      projects: store.content.projects,
      experiments: store.content.experiments,
      sectionObserver: null
    }
  },
  methods: {
  observeSections() {
    // try {
    //   this.sectionObserver.disconnect()
    // } catch (err) {}

    const options = {
      rootMargin: '5px 0px -40% 0px',
      threshold: 0.01
    }
    this.sectionObserver = new IntersectionObserver(this.sectionObserverHandler, options)
  
    // Observe each section
    const sections = document.querySelectorAll('.section')
    sections.forEach(section => {
      this.sectionObserver.observe(section)
    })
  },
  sectionObserverHandler (entries) {
    for (const entry of entries) {
      if (entry.isIntersecting) {
         const sectionId = entry.target.id
         // Push sectionId to router here 
        //  this.$router.replace({ name: this.$route.name, hash: `#${sectionId}` })
        // console.log(this.$router);
        if(this.$route.name !== sectionId){

          this.$router.replace({ name: sectionId})
        }
      }
    }
  }
}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#mainPageContent{
  grid-area: content;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
    // @media only screen and (min-width: 700px) {
    // display: flex;
    // flex-direction: colum;
    // flex-wrap: wrap;
    // align-items: stretch;
    

    
  //}
 
}
.layout{
  display: grid;

  @media screen 
  and (min-device-width: 1000px){ 
    grid-template-columns: 1fr 1fr;

}
  grid-template-columns: 1fr;
}

.about-text{
  padding: $padding;
  
  
  

}
.about-image{
  
  object-fit: cover;
  border-right: $border;
  max-width: 100vw;
  @media screen 
  and (min-device-width: 1000px){ 
  max-width: 30vw;

}
  
}
.about-container{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  justify-content: space-between;

    @media screen 
  and (min-device-width: 1000px){ 
    flex-direction: row;
    width: inherit;

}
.info{
  flex: 3;
  border-right: $border;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}
.links-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

  // min-height: 60vh;
}
.link-button:hover{
  background-color: $primaryColor;
  color: $backgroundColor;
}
.icon{
  stroke: $primaryColor;
  stroke-width: $lines;
}
.link-button:hover .icon{
  stroke: $backgroundColor;
}
.link-button{
  // border-right: $border;
  border-top: $border;
  padding: 20px;
  text-decoration: none;
  color: $fontColor;
  cursor: url("../assets/icons/cursorCircle.svg"), pointer;
  
}

#container > a:first-child + a{
        border-left: $border;
        border-right: $border;
    }

    .arrow{
      stroke: $primaryColor;
  stroke-width: $lines;
  margin: 0.7rem;
   transition: translate 0.3s ease-in-out 0ms;
}

.link-button:hover .arrow {
  translate: 0.4rem 0rem;
   stroke: $backgroundColor;
}

.change-button{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 1rem;
}



</style>
